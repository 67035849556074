@import './variables';
@import './mui';

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  text-align: center;
  background-color: $gray-50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.auth-background {
  background-image: url(../assets/bg-auth3x-mobile.png);
  background-repeat: repeat-x;
  background-position: bottom center;
  background-size: 300px;
  background-color: $gray-unlabeled;

  @media (min-width: 1024px) {
    background-image: url(../assets/bg-auth3x-desktop.png);
    background-size: 100%;
  }
}
