// gray: cool gray
$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #9ca3af;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;
$gray-unlabeled: #f4f5f7;

// red
$red-50: #fef2f2;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-700: #b91c1c;
$red-800: #991b1b;
$red-900: #7f1d1d;

// orange
$orange-50: #fff7ed;
$orange-100: #ffedd5;
$orange-200: #fed7aa;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #ea580c;
$orange-700: #c2410c;
$orange-800: #9a3412;
$orange-900: #7c2d12;

// yellow: amber
$yellow-50: #fffbeb;
$yellow-100: #fef3c7;
$yellow-200: #fde68a;
$yellow-400: #fbbf24;
$yellow-500: #f59e0b;
$yellow-600: #d97706;
$yellow-700: #b45309;
$yellow-800: #92400e;
$yellow-900: #78350f;

// green: emerald
$green-50: #ecfdf5;
$green-100: #d1fae5;
$green-200: #a7f3d0;
$green-400: #34d399;
$green-500: #10b981;
$green-600: #059669;
$green-700: #047857;
$green-800: #065f46;
$green-900: #064e3b;

// teal
$teal-50: #f0fdfa;
$teal-100: #ccfbf1;
$teal-200: #99f6e4;
$teal-400: #2dd4bf;
$teal-500: #14b8a6;
$teal-600: #0d9488;
$teal-700: #0f766e;
$teal-800: #115e59;
$teal-900: #134e4a;

// cyan
$cyan-50: #ecfeff;
$cyan-100: #cffafe;
$cyan-200: #a5f3fc;
$cyan-400: #22d3ee;
$cyan-500: #06b6d4;
$cyan-600: #0891b2;
$cyan-700: #0e7490;
$cyan-800: #155e75;
$cyan-900: #164e63;

// light blue
$light-blue-50: #f0f9ff;
$light-blue-100: #e0f2fe;
$light-blue-200: #bae6fd;
$light-blue-400: #38bdf8;
$light-blue-500: #0ea5e9;
$light-blue-600: #0284c7;
$light-blue-700: #0369a1;
$light-blue-800: #075985;
$light-blue-900: #0c4a6e;

// blue
$blue-50: #eff6ff;
$blue-100: #dbeafe;
$blue-200: #bfdbfe;
$blue-400: #60a5fa;
$blue-500: #3b82f6;
$blue-600: #2563eb;
$blue-700: #1d4ed8;
$blue-800: #1e40af;
$blue-900: #1e3a8a;

// indigo
$indigo-100: #f2ecfe;
$indigo-200: #e5d8fb;
$indigo-300: #cbb0f8;
$indigo-400: #b089f4;
$indigo-500: #9661f1;
$indigo-600: #7c3aed;
$indigo-700: #5d2cb2;
$indigo-800: #3e1d77;
$indigo-900: #1f0f3b;

// purple (violet)
$purple-50: #f5f3ff;
$purple-100: #ede9fe;
$purple-200: #ddd6fe;
$purple-400: #a78bfa;
$purple-500: #8b5cf6;
$purple-600: #7c3aed;
$purple-700: #6d28d9;
$purple-800: #5b21b6;
$purple-900: #4c1d95;

// pink
$pink-50: #fdf2f8;
$pink-100: #fce7f3;
$pink-200: #fbcfe8;
$pink-400: #f472b6;
$pink-500: #ec4899;
$pink-600: #db2777;
$pink-700: #be185d;
$pink-800: #9d174d;
$pink-900: #831843;

$white: #ffffff;
