//#region MUI FormControl

.MuiFormControl-root {
  position: relative;
}

//#endregion

//#region MUI InputLabel

.input-label {
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: $gray-700;
    left: -10px;
    font: normal normal 500 18px/20px Inter;
  }
  &.MuiInputLabel-outlined {
    pointer-events: auto;
  }
}

//#endregion

//#region MUI Inputs: Select and TextField

%input {
  padding-block: 0.6rem;
  color: $gray-900;
  background-color: $white;
  font: normal normal normal 14px/17px Inter;
}

.MuiOutlinedInput-root {
  margin-top: 1rem;
  border-radius: 6px;

  input::placeholder {
    color: $gray-500;
  }

  input,
  .MuiSelect-select {
    @extend %input;
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid $gray-300;
    border-radius: 6px;
  }

  &:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-color: $indigo-500;
  }
}

.search-input {
  &.MuiOutlinedInput-root,
  & > .MuiOutlinedInput-root {
    margin-block: 0;
  }
}

.expandable-input {
  border: none;

  &.MuiOutlinedInput-root,
  & > .MuiOutlinedInput-root {
    margin-block: 0;
  }

  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-radius: 6px;
    border: 1px solid $gray-50;
    cursor: pointer;
  }

  .MuiSelect-select {
    border: none;

    & ~ fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
}

.input-element.MuiSelect-select {
  @extend %input;
  padding-inline: 1rem 2rem;

  option {
    color: $gray-500;
  }
}

.Mui-InputBase-input:focus {
  outline: none;
  padding: 3rem;
}

.Mui-disabled {
  &.input-disabled,
  &.MuiOutlinedInput-root,
  &.MuiSelect-select {
    font: normal normal normal 14px/17px Inter;
    color: $gray-700;
    background-color: $gray-100;
    border-color: $gray-500;
  }
}

.input-error {
  &.Mui-error {
    color: $red-900;
  }

  &.MuiFormHelperText-root {
    color: $red-500;
  }

  &.Mui-error {
    border-color: $red-500;
  }

  &.MuiFormHelperText-root {
    font: normal normal normal 14px/17px Inter;
    position: absolute;
    bottom: -0.5rem;
    left: 0;
  }
}

//#endregion
